<template>
  <!-- 1. Sort out the issue with changing hospital and no specialities appearing --->
  <!-- 2. The issue with grand summary front page --> <!-- fixed-->
  <!-- 3. The issues with op improvements - needs updating --> 
  <!-- -->
  <!-- New Issues -->
  <!-- Graph data not appearing on page refresh -->
  <!-- Hospital Grans summary for RMS  - need to show -->
  <!-- sort out data retrieval - do it too many times for the tiles -->
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Projected Demand Capacity - System Population</h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">             
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey</span>
        </div>
      </div>   
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage')"
          :class="stageSummary == 'stage' ? 'active' : ''"
        >
          <span>Level 3</span>
        </li>
        <li
          @click="editStageSummary('specialities')"
          :class="stageSummary == 'specialities' ? 'active' : ''"
        >
          <span>Specialities</span>
        </li>
        <li
          @click="editStageSummary('buildingsFM')"
          :class="stageSummary == 'buildingsFM' ? 'active' : ''"
        >
          <span>Buildings and FM</span>
        </li>
        <li
          @click="editStageSummary('opImprov')"
          :class="stageSummary == 'opImprov' ? 'active' : ''"
        >
          <span>Operational Improvement</span>
        </li>  
        <li
          @click="editStageSummary('metrics')"
          :class="stageSummary == 'metrics' ? 'active' : ''"
        >
          <span>Population Demand Capacity Metrics</span>
        </li>     
      </ul>
    </nav>

    <div v-if="stageSummary == 'stage'">
      <div class="route-content">

        <!-- button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="displayData">Display Data</button -->

      <div class="stat-cards">
    
          <div class="stat-cards--item single no-hover" style="width:300px;">
            <span class="card-header">Population ({{ this.yeardata[0]  }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
              <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[0]) }}</span>
            </div>
          </div>

          <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear1' ? 'active' : ''"  @click="fillData('patientEpisodeYear1',yeardata[0])">
            <span class="card-header">Patient Episode Productivity ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-patient.svg" alt="Patient Episodes"/>
              <span class="card-total">{{ getTileData(0) }}</span>
            </div>
            <span class="card-info">Total patient episodes</span>
          </div>

          <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'assetsYear1' ? 'active' : ''" @click="fillData('assetsYear1',yeardata[0])">
            <span class="card-header">Assets ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total">{{ getTileData(1) }}</span>
            </div>
            <span class="card-info">Total Assets</span>
          </div>

          <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'workforceCostYear1' ? 'active' : ''" @click="fillData('workforceCostYear1',yeardata[0])">
            <span class="card-header">Workforce ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
              <span class="card-total">{{ getTileData(2) }}</span>
            </div>
            <span class="card-info">Total Workforce Full Time Equivalent (FTE)</span>
          </div>

          <div class="stat-cards--item single" style="width:300px" :class="activeSummary === 'financeYear1' ? 'active' : ''" @click="fillData('financeYear1',yeardata[0])">
            <span class="card-header">Finance ({{ this.yeardata[0] }})</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total">{{ getTileData(3) }}</span>
            </div>
            <span class="card-info">Budget Requirement</span>
          </div>
        

      

        <!-- line 2 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{ this.yeardata[1] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[1]) }} </span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear2' ? 'active' : ''"  @click="fillData('patientEpisodeYear2',yeardata[1])">
          <span class="card-header">Patient Episode Productivity ({{ this.yeardata[1] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(4) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear2' ? 'active' : ''" @click="fillData('assetsYear2',yeardata[1])">
          <span class="card-header">Assets ({{ this.yeardata[1] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(5) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear2' ? 'active' : ''" @click="fillData('workforceCostYear2',yeardata[1])">
          <span class="card-header">Workforce ({{ this.yeardata[1] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(6) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear2' ? 'active' : ''" @click="fillData('financeYear2', yeardata[1])">
          <span class="card-header">Finance ({{ this.yeardata[1] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(7) }}</span>
          </div>
          <span class="card-info"></span>
        </div>
      

      
        <!-- line 3 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{this.yeardata[2]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[2]) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px; " :class="activeSummary === 'patientEpisodeYear3' ? 'active' : ''"  @click="fillData('patientEpisodeYear3',yeardata[2])">
          <span class="card-header">Patient Episode Productivity ({{ this.yeardata[2] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(8) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear3' ? 'active' : ''" @click="fillData('assetsYear3', yeardata[2])">
          <span class="card-header">Assets ({{ this.yeardata[2] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(9) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear3' ? 'active' : ''" @click="fillData('workforceCostYear3',yeardata[2])">
          <span class="card-header">Workforce ({{ this.yeardata[2] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(10) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear3' ? 'active' : ''" @click="fillData('financeYear3',yeardata[2])">
          <span class="card-header">Finance ({{ this.yeardata[2] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(11) }}</span>
          </div>
          <span class="card-info"></span>
        </div>
      

        <!-- line 4 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[3]) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear4' ? 'active' : ''"  @click="fillData('patientEpisodeYear4',yeardata[3])">
          <span class="card-header">Patient Episode Productivity ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(12) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear4' ? 'active' : ''" @click="fillData('assetsYear4', yeardata[3])">
          <span class="card-header">Assets ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(13) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear4' ? 'active' : ''" @click="fillData('workforceCostYear4', yeardata[3])">
          <span class="card-header">Workforce ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(14) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear4' ? 'active' : ''" @click="fillData('financeYear4', yeardata[3])">
          <span class="card-header">Finance ({{ this.yeardata[3] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(15) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <!-- line 5 -->
        <div class="stat-cards--item single no-hover" style="width:300px;">
          <span class="card-header">Population ({{ this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total"> {{ this.getPopulationTotal(this.yeardata[4]) }}</span>
          </div>
        </div>


        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'patientEpisodeYear5' ? 'active' : ''"  @click="fillData('patientEpisodeYear5',yeardata[4])">
          <span class="card-header">Patient Episode Productivity ({{ this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-patient.svg" alt="SupplyChain" />
            <span class="card-total">{{ getTileData(16) }}</span>
          </div>
        </div>

        <div class="stat-cards--item single" style="width:300px;"  :class="activeSummary === 'assetsYear5' ? 'active' : ''" @click="fillData('assetsYear5',yeardata[4]) ">
          <span class="card-header">Assets ({{ this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
            <span class="card-total">{{ getTileData(17) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'workforceCostYear5' ? 'active' : ''" @click="fillData('workforceCostYear5',yeardata[4])">
          <span class="card-header">Workforce ({{this.yeardata[4]}})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-workforce.svg" alt="Workforce" />
            <span class="card-total">{{ getTileData(18) }}</span>
          </div>
          <span class="card-info"></span>
        </div>

        <div class="stat-cards--item single" style="width:300px;" :class="activeSummary === 'financeYear5' ? 'active' : ''" @click="fillData('financeYear5',yeardata[4])">
          <span class="card-header">Finance ({{ this.yeardata[4] }})</span>
          <div class="card-icon">
            <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
            <span class="card-total">{{ getTileData(19) }}</span>
          </div>
          <span class="card-info"></span>
        </div>
      </div>

        <div v-if="activeSummary == 'workforceCostYear1' || activeSummary == 'workforceCostYear2' || activeSummary == 'workforceCostYear3' || activeSummary == 'workforceCostYear4' || activeSummary == 'workforceCostYear5'">
          <h1 class="text-3xl my-6 active">Workforce Summary</h1>

          <div class="chartcontainer3">
            <div class="title_text3">WORKFORCE</div>
            <div clas="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Workforce</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Staff Cost</th>
                <th class="border px-4 py-2">Total Workforce Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(workforce, index) in workforceSummaryTableWorkforceFilter"
                    :key="index"
                  >
                    {{ workforce  }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(quantity, index) in workforceSummaryTableQuantityFilter"
                    :key="index"
                  >
                    {{ quantity | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(costPerStaff, index) in workforceSummaryTableCostPerStaffFilter"
                    :key="index"
                  >
                    {{ costPerStaff | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalWorkforceCost, index
                    ) in workforceSummaryTableTotalWorkforceCostFilter"
                    :key="index"
                  >
                    {{ totalWorkforceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="activeSummary == 'assetsYear1' || activeSummary == 'assetsYear2' || activeSummary == 'assetsYear3' || activeSummary == 'assetsYear4' || activeSummary == 'assetsYear5'">
          <!-- start -->
          <h1 class="text-3xl my-6 active">Assets Summary</h1>

          <div class="chartcontainer3">
            <div class="title_text3">ASSETS</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Assets</th>
                <th class="border px-4 py-2">Quantity</th>
                <th class="border px-4 py-2">Capital Cost Per Item</th>
                <th class="border px-4 py-2">Total Capital Cost</th>
                <th class="border px-4 py-2">Life Cycle</th>
                <th class="border px-4 py-2">Annual Cost</th>
                <th class="border px-4 py-2">Annual Maintenance Cost</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(assets, index) in assetsFilter" :key="index">
                    {{ assets }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(assetsQuantity, index) in assetsQuantityFilter"
                    :key="index"
                  >
                    {{ assetsQuantity | numeral }}
                  </div>
                </td>
                <td class="border">
                  <div
                    v-for="(
                      capitalCostPerItem, index
                    ) in assetsCapitalCostPerItemFilter"
                    :key="index"
                  >
                    {{ capitalCostPerItem | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      totalCapitalCost, index
                    ) in assetsTotalCapitalCostFilter"
                    :key="index"
                  >
                    {{ totalCapitalCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(lifeCycle, index) in assetsLifeCycleFilter"
                    :key="index"
                  >
                    {{ lifeCycle }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedCost, index
                    ) in assetsAnnualisedCostFilter"
                    :key="index"
                  >
                    {{ annualisedCost | numeral }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      annualisedMaintenanceCost, index
                    ) in assetsAnnualisedMaintenanceCostFilter"
                    :key="index"
                  >
                    {{ annualisedMaintenanceCost | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- finish -->

        <div v-if="activeSummary == 'patientEpisodeYear1' || activeSummary == 'patientEpisodeYear2' || activeSummary == 'patientEpisodeYear3' || activeSummary == 'patientEpisodeYear4' || activeSummary == 'patientEpisodeYear5'">
          <h1 class="text-3xl my-6 active">Patient Episodes</h1>
          <!-- start -->
          <div class="chartcontainer3">
            <div class="title_text3">PATIENT EPISODES</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
            </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Patient Episodes</th>
                <th class="border px-4 py-2">Patient Episodes Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityPatientEpisodesFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(
                      patientEpisodesQuantity, index
                    ) in specialityPatientEpisodesTotalFilter"
                    :key="index"
                  >
                    {{ patientEpisodesQuantity | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /div -->

        <div v-if="activeSummary == 'financeYear1' || activeSummary == 'financeYear2' || activeSummary == 'financeYear3' || activeSummary == 'financeYear4' || activeSummary == 'financeYear5'">
          <h1 class="text-3xl my-6 active">Finance</h1>

          <!-- start -->

          <div class="chartcontainer3">
            <div class="title_text3">FINANCE</div>
            <div class="chart_view3">
            <Bar
              :chart-options="chartOptions"
              :chart-data="datacollection"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>
          </div>

          <div>
            <h1><br /></h1>
          </div>

          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Finance</th>
                <th class="border px-4 py-2">Finance Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div
                    v-for="(
                      patientEpisodes, index
                    ) in specialityFinanceNameFilter"
                    :key="index"
                  >
                    {{ patientEpisodes }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(financeName, index) in specialityFinanceTotalFilter"
                    :key="index"
                  >
                    {{ financeName | numeral }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- finish -->
      </div>
    </div>

      <div v-if="stageSummary == 'metrics'">
        <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
                    <!-- used to be :options=getOptions-->
          <div class="route-speciality--box">
          <div class="box">
          <span class="box-header">Metrics Year</span>
          <span class="box-label">
            <v-select
              v-model="metricYear"
              label="year"
              :clearable="false"
              @input="setMetricYear"
              :options="$store.state.prototypedata.years"
            />
          </span>
        </div>
        </div>          
        </div>

        <div v-if="metricUpdate">
          <div class="route-content">
          <!-- 1 open -->
          <table class="table-primary">
            <!--thead>
              <tr>
                <th class="border px-4 py-2">Area</th>
                <th class="border px-4 py-2">Total Floor Space</th>
                <th class="border px-4 py-2">Full Development Cost</th>
                <th class="border px-4 py-2">Net Build Cost</th>
                <th class="border px-4 py-2">Avg FM Cost</th>                
              </tr>
            </thead -->
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in metricActivityFilter" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanPatients, index) in metricBeneficiaryFilter" :key="index">
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanPatients }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanperSessions, index) in metricMetricsFilter" :key="index">
                    <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanperSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanannSessions, index) in metricHSIBenchmarkFilter" :key="index">
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanannSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanweekSessions, index) in metricInternationalBenchmarkFilter" :key="index">
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>                
              </tr>
            </tbody>
          </table>
        </div>
        </div>       
      </div>
    </div>

    <div v-if="stageSummary == 'opImprov'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Improvement Area</span>
              <span class="box-label">
                <v-select
                  v-model="improvementSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes2"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="improvementType == 'diagnostics'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily Diagnostics Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily Diagnostics Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: by Modality</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: by Modality<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Commence uplift of Day-Case Activity - reduction of IP and ALOS</td>
                    <td class="border" style="padding: 10px;">20</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Commence uplift of Day-Case Activity - reduction of IP and ALOS<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Trauma Diagnostics 24/7 capacity, including day-case over-bookings</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Trauma Diagnostics 24/7 capacity, including day-case over-bookings<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                </tbody>
              </table>          
            <!-- /div -->
          </div>
        </div>

           <!-- now check to see if any of the specialities areas have changed -->
           <div v-if="improvementType == 'OPD'">
            <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily OPD Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily OPD Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: by Pre-Ops</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: by Pre-Ops<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">OPD Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OPD Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>


        <div v-if="improvementType == 'OR'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>                                   
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily OR Coordination </td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily OR Coordination<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Pre-Ops</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Pre-Ops<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Pre-Session Theatre Briefing (WHO)</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Pre-Session Theatre Briefing (WHO)<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Early Knife-Skin</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Early Knife-Skin<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Reception - OR - Recovery Turnaround Timing</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Reception - OR - Recovery Turnaround Timing<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Ward - OR - Ward</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Ward - OR - Ward<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">OR Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">OR Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty for IP and DC<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Commence uplift of Day-Case Activity - reduction of IP and ALOS</td>
                    <td class="border" style="padding: 10px;">20</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Commence uplift of Day-Case Activity - reduction of IP and ALOS<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Trauma OR 24/7 capacity, including day-case over-bookings</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Trauma OR 24/7 capacity, including day-case over-bookings<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'ER'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 3</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily ER Coordination</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily ER Coordination <div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Minor Escalation</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Minor Escalation<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Establish Operational SOPs: Major Escalation</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Operational SOPs: Major Escalation<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">ER Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">ER Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>                                                                     
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'bedflow'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 2</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Daily Ward-Based Discharge Planning Process pre-Ward Rounds </td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Daily Ward-Based Discharge Planning Process pre-Ward Rounds<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Operational BedFLow SOPs</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Operational BedFLow SOPs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm Multi-disciplinary Specialist Consultant ER attendance</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Multi-disciplinary Specialist Consultant ER attendance<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">Confirm Consultant Coverage per Specialty per Bed</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Consultant Coverageg per Specialty per Bed<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  <tr>
                    <td class="border" style="padding: 10px;">ER Coordination and Activity linked to TOM</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">ER Coordination and Activity linked to TOM<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>  
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Consultant of the Week or similar cover per Bed, 7 days per week</td>
                    <td class="border" style="padding: 10px;"></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Consultant of the Week or similar cover per Bed, 7 days per week<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm General Management oversight of daily lists</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm General Management oversight of daily lists<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>    <tr>
                    <td class="border" style="padding: 10px;">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery</td>
                    <td class="border" style="padding: 10px;">2</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Daily Reporting of Actual versus TOM per clinical specialty  for Trauma Surgery<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
      
                </tbody>
              </table>
            <!-- /div -->
          </div>  
        </div>
      </div>
    </div>

    <!-- :options="$store.state.prototypedata.specialities2" -->
    <div v-if="stageSummary == 'specialities'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          <!-- div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Hospital</span>
              <span class="box-label">
                <v-select
                  v-model="selectedNewHospital"
                  label="speciality"
                  :clearable="false"
                  @input="setNewHospital"
                  :options=$store.state.prototypedata.newHospitals
                />
              </span>
            </div>
          </div -->

          <!-- used to be :options=getOptions-->
          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Discipline</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpeciality"
                  label="speciality"
                  :clearable="false"
                  @input="setSpeciality"
                  :options="$store.state.prototypedata.specialities2"
                />
              </span>
            </div>
          </div>

          <div class="route-speciality--box">
          <div class="box">
          <span class="box-header">Speciality Year</span>
          <span class="box-label">
            <v-select
              v-model="selectedYear"
              label="year"
              :clearable="false"
              @input="setYear"
              :options="$store.state.prototypedata.yearsStage3"
            />
          </span>
        </div>
        </div>

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Area</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="specialityType == 'patientEpisodes'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Patient Episodes</th>
                    <th class="border px-4 py-2">Patient Episodes Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialitiesPatientEpisodesFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodesQuantity, index
                        ) in specialitiesPatientEpisodesTotalFilter"
                        :key="index"
                      >
                        {{ patientEpisodesQuantity | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'finance'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Finance</th>
                    <th class="border px-4 py-2">Finance Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialitiesFinanceNameFilter"
                        :key="index"
                      >
                        {{ patientEpisodes }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialitiesFinanceTotalFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'space'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Space & Property</th>
                    <th class="border px-4 py-2">Quantity</th>
                    <th class="border px-4 py-2">Capital Cost Per Item</th>
                    <th class="border px-4 py-2">Total Capital Cost</th>
                    <th class="border px-4 py-2">Life Cycle</th>
                    <th class="border px-4 py-2">Annualised Cost</th>
                    <th class="border px-4 py-2">
                      Annualised Maintenance Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          spaceAndProperty, index
                        ) in specialitiesSpaceAndPropertyFilter"
                        :key="index"
                      >
                        {{ spaceAndProperty }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          spaceAndPropertyQuantity, index
                        ) in specialitiesSpaceAndPropertyQuantityFilter"
                        :key="index"
                      >
                        {{ spaceAndPropertyQuantity | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          capitalCostPerItem, index
                        ) in specialitiesCapitalCostPerItemFilter"
                        :key="index"
                      >
                        {{ capitalCostPerItem | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          totalCapitalCost, index
                        ) in specialitiesTotalCapitalCostFilter"
                        :key="index"
                      >
                        {{ totalCapitalCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(lifeCycle, index) in specialitiesLifeCycleFilter"
                        :key="index"
                      >
                        {{ lifeCycle }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedCost, index
                        ) in specialitiesAnnualisedCostFilter"
                        :key="index"
                      >
                        {{ annualisedCost | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedMaintenanceCost, index
                        ) in specialitiesAnnualisedMaintenanceCostFilter"
                        :key="index"
                      >
                        {{ annualisedMaintenanceCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'workforce'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Workforce</th>
                    <th class="border px-4 py-2">Workforce Quantity</th>
                    <th class="border px-4 py-2">Cost Per Staff</th>
                    <th class="border px-4 py-2">Total Workforce Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          workforceName, index
                        ) in specialitiesWorkforceNameFilter"
                        :key="index"
                      >
                        {{ workforceName }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialitiesWorkforceQuantityFilter"
                        :key="index"
                      >
                        {{ financeName | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceCostPerStaff, index
                        ) in specialitiesWorkforceCostPerStaffFilter"
                        :key="index"
                      >
                        {{ workforceCostPerStaff | numeral }}
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceTotalCost, index
                        ) in specialitiesWorkforceTotalWorkforceCostFilter"
                        :key="index"
                      >
                        {{ workforceTotalCost | numeral }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


   
    <div v-if="stageSummary == 'buildingsFM'">

      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
                    <!-- used to be :options=getOptions-->
          <div class="route-speciality--box">
          <div class="box">
          <span class="box-header">Buildings Year</span>
          <span class="box-label">
            <v-select
              v-model="buildingsYear"
              label="year"
              :clearable="false"
              @input="setBuildingsYear"
              :options="$store.state.prototypedata.yearsStage3"
            />
          </span>
        </div>
        </div>          
        </div>

        <div v-if="buildUpdate">
          <div class="route-content">
          <!-- 1 open -->
          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Area</th>
                <th class="border px-4 py-2">Total Floor Space</th>
                <th class="border px-4 py-2">Full Development Cost</th>
                <th class="border px-4 py-2">Net Build Cost</th>
                <th class="border px-4 py-2">Avg FM Cost</th>         
                <th class="border px-4 py-2">FM Cost</th>        
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in areaAreaFilter" :key="index">
                    {{ jobPlan }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanPatients, index) in areaTFSFilter"
                    :key="index"
                  >
                    {{ jobPlanPatients }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanperSessions, index) in areaNBCFilter"
                    :key="index"
                  >
                    {{ jobPlanperSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanannSessions, index) in areaFDCFilter"
                    :key="index"
                  >
                    {{ jobPlanannSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaAFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaFMCostFilter"
                    :key="index"
                  >
                    {{ jobPlanweekSessions }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>  
      
      </div>
    </div>
  </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "stage",
      selectedSpeciality: "AHPs",
      selectedYear: "2027",
      metricYear: "2025",
      buildingsYear: "2025",
      //selectedNewHospital: "BOC",
      improvementSpecialityArea: "Diagnostics",
      //selectedHospital: "MH",
      selectedHospitalPrefix: "_england_",
      apiPayload: { reference: "", hospital: "", speciality: "", apiString: "", year: "", stage: "3", yearApi: "" },
      activeSummary: "workforceCostYear1",      
      improvementType: "Diagnostics",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "Patient Episodes",
      specialityApi: "ahps_surrey_stage_3_2023_collection",
      tileInformation: {},
      firstUpdate: true,  
      stageThreeData: [],
      activeStageYear: "2025",
      metricActivity: [],
      metricMetrics: [],
      metricBeneficiary: [],
      metricHsiBenchmark: [],
      metricInternationalBenchmark: [],
      metricUpdate: false,
      buildArea: [],
      buildTFS: [],
      buildNBC: [],
      buildFDC: [],
      buildAFM: [],
      buildFM: [],
      buildUpdate: false,
      message: "<BR />",      
      yeardata: [],
      //chartOptions: {
      //  responsive: true
      //},
    };
  },
  updated () {
    //console.log ("updated: ")
    if (this.firstUpdate == true)
    {
      //console.log ("update: update only once");

       //var fullAPIString = "get" + this.selectedHospitalPrefix;

        //console.log ("4. ", fullAPIString);

        //var fullAPIString = this.getApiPrefix();

        this.fetchDataSummaries();

        // DO FOR ALL YEARS
        for (var i = 0; i< this.yeardata.length; i++)
        {
          this.getBarGraphDataValues(this.yeardata[i]);
        }
        

        this.fillData(this.activeSummary, this.activeStageYear);         

        this.firstUpdate = false;
    }
    
  },
  beforeMount() {
    //console.log("Summary3.vue before mounted");
    this.apiPayload.apiString = "grandsummarytwo-collection";
    this.apiPayload.reference = "1";
  
    //var fullAPIString = "get" + this.selectedHospitalPrefix;

    //var fullAPIString = this.getApiPrefix();

    //console.log ("1. ", fullAPIString);
    //console.log ("->1");
    this.fetchDataSummaries ();
    //console.log ("<-1");


  },
  mounted() {
    //const speciality = {
//      speciality: "Grand Summary 3",
  //    apiString: "grandsummarythreeyearone-collection",
    //};
    //console.log ("->2a");
    //this.fetchData(speciality);

    //console.log ("->2b");

    this.apiPayload.speciality = "AHPs";
    this.apiPayload.apiString = "grandsummarythreeyearone-collection";
    this.apiPayload.year = "2025";
    //this.apiPayload.hospital = "boc";
    this.apiPayload.reference = "2";

    this.datacollection = {};

    //console.log ("mounted - apiPayload", this.apiPayload);

    //var fullAPIString = "get" + this.selectedHospitalPrefix;

    //console.log ("2 ", fullAPIString);

    console.log ("stuff: ", this.$store.state.prototypedata.yearsStage3);

    for (var i= 0; i < this.$store.state.prototypedata.yearsStage3.length; i++)
    { 
        this.yeardata.push ( this.$store.state.prototypedata.yearsStage3[i].year);
        console.log ("year: ",this.$store.state.prototypedata.yearsStage3[i].year);
    }

    //console.log ("->2c");

    //var fullAPIString = this.getApiPrefix();

    this.fetchDataSummaries();

    //console.log ("->2d");

    this.fillData("assetsYear1", this.activeStageYear);
    //console.log ("->2e");


    //this.getOptionsMethod();
  },
  watch: {
    selectedSpecialityArea: {
      handler: function (val) {
        //console.log(
        //  "selectedSpecialityArea: Value Change: ",
        //  val.label,
        //  val.string
        //);
        this.editSpecialityArea(val.label);
        //console.log("speciality: ", this.specialityApi);
        this.apiPayload.apiString = this.specialityApi;
        this.apiPayload.reference = "3";
        //this.getMongoDBData();
      },
      deep: true,
    },  
    improvementSpecialityArea: {
      handler: function (val) {
        //console.log(
         // "improvementArea: Value Change: ",
          //val.label,
          //val.string
       // );
        this.editImprovementType(val.label);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
    activeSummary: {
      handler: function (newvalue) {
        //console.log("The new activeSummary value is ", newvalue);
        this.fillData (newvalue, this.activeStageYear);
      },
      deep: true,
    },
    stageSummary: {
      handler: function () {
        
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityData() {
      //console.log(
//        "specialityData: ",
  //      this.$store.state.prototypedata.specialityData.length
    //  );
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    stageThreeYearOne()
    {
      if (this.$store.state.prototypedata.stageThreeYearOne.length > 0)
       {
        return true;
       }

       return null;
    },
    stageThreeYearTwo()
    {
      if (this.$store.state.prototypedata.stageThreeYearTwo.length > 0)
       {
        return true;
       }

       return null;
    },
    stageThreeYearThree()
    {
      if (this.$store.state.prototypedata.stageThreeYearThree.length > 0)
       {
        return true;
       }

       return null;
    },
    stageThreeYearFour()
    {
      if (this.$store.state.prototypedata.stageThreeYearFour.length > 0)
       {
        return true;
       }

       return null;
    },
    stageThreeYearFive()
    {
      if (this.$store.state.prototypedata.stageThreeYearFive.length > 0)
       {
        return true;
       }

       return null;
    },
    stageData()
    {
       if (this.$store.state.prototypedata.stageThreeYearOne.length > 0)
       {
        return true;
       }

       return null;
    },
    getSpecialityAreaWorkforceIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.specialityData.length; i++) {
        if ("cost_per_staff" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
    getAreaIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.stageThree.length; i++) {
        if ("total_floor_space" in this.$store.state.prototypedata.stageThree[i]) {
          index = i;
        }
      }

      return index;
    },
    
    
    getSpecialityAreaPatientIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.specialityData.length; i++) {
        if (
          "patient_episodes_total" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getSpecialityAreaSpaceIndex() {
      var i = 0;
      var index = 0;

      if (this.activeStageYear == this.yeardata[0])

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "space_and_property" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getAssetIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.stageThree.length; i++) {
        if (
          "annualised_cost" in this.$store.state.prototypedata.stageThree[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getSpecialityAreaFinanceIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.specialityData.length; i++) {
        if ("finance_total" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
 
    getPopulationIndex() {
      var i = 0;
      var index = 0;

      for (i = 0; i < this.$store.state.prototypedata.specialityData.length; i++) 
      {
        if ("population" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },

    metricActivityFilter() {
      return this.metricActivity;
    },
    // metricBeneficiaryFilter
    metricBeneficiaryFilter() {
        return this.metricBeneficiary;
      
    },
    metricMetricsFilter() {
        return this.metricMetrics;
    },
    metricHSIBenchmarkFilter() {
      return this.metricHsiBenchmark;
       

    },
    metricInternationalBenchmarkFilter() {
        return  this.metricInternationalBenchmark;
    },
    isPatientData()
    {
      var exists = false;

      //console.log ("isPatientData: ", this.activeSummary);
      if (this.activeSummary == 'patientEpisodeYear1' || 
          this.activeSummary == 'patientEpisodeYear2' ||
          this.activeSummary == 'patientEpisodeYear3' ||
          this.activeSummary == 'patientEpisodeYear4' || 
          this.activeSummary == 'patientEpisodeYear5' 
          )

           {
            exists = true;
           }

      //console.log ("exists = ", exists);

       return exists;
    },      
    getPopulation() {
      //var i = this.getPopulationIndex;

      //console.log ("population index ", i);
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPopulationIndex
        ].population[0];
      }
      return null;
    },
    specialitiesWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].total_workforce_cost;
      }
      return null;
    },
    specialitiesWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex].total_workforce_cost;
      }
      return null;
    },
    specialitiesWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex].workforce;
      }
      return null;
    },
    // Workforce Summary Table -- Workforce column
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex].workforce;
      }
      return null;
    },
    specialitiesWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    // Workforce Table -- Quantity column
    specialityWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    workforceSummaryTableWorkforceFilter() {
      return this.getDataSlice (this.activeStageYear, "cost_per_staff", "workforce");
    },
    workforceSummaryTableQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableQuantityFilter)
      );

      return str;
    },
    assetsTotalCapitalCostFilter() 
    {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "total_capital_cost");
    },
    assetsCapitalCostPerItemFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "capital_cost_per_item");
    },
    workforceSummaryTableQuantityFilter() {
      return this.getDataSlice (this.activeStageYear, "cost_per_staff", "workforce_quantity");
    },
    workforceSummaryTableCostPerStaffFilter() {
      return this.getDataSlice (this.activeStageYear, "cost_per_staff", "cost_per_staff");
    },
    workforceSummaryTableCostPerStaffFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableCostPerStaffFilter)
      );

      return str;
    },
    workforceSummaryTableTotalWorkforceCostFilter() {
      return this.getDataSlice(this.activeStageYear, "cost_per_staff", "total_workforce_cost");
    },
    workforceSummaryTableTotalWorkforceCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableTotalWorkforceCostFilter)
      );

      return str;
    },
    specialityPatientEpisodesTotalFilter() {
      return this.getDataSlice(this.activeStageYear, "patient_episodes_total", "patient_episodes_total");
    },
    specialitiesSpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].space_and_property;
      }
      return null;
    },
    specialitiesSpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].space_and_property_quantity;
      }
      return null;
    },
    specialitiesCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].capital_cost_per_item;
      }
      return null;
    },
    specialitiesTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].total_capital_cost;
      }
      return null;
    },
    specialitiesLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].life_cycle;
      }
      return null;
    },
    specialitiesAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].annualised_cost;
      }
      return null;
    },
    specialitiesAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaSpaceIndex
        ].annualised_maintenance_cost;
      }
      return null;
    },
    areaAreaFilter() {
      /*if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .area;
      }*/
      return this.buildArea;
    },
    areaTFSFilter() {
      /*if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .total_floor_space;
      }
      return null;*/
      return this.buildTFS;
    },
    areaNBCFilter() {
      /*if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .net_build_cost;
      }
      return null;*/
      return this.buildNBC;
    },
    areaFDCFilter() {
      /*if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .full_dev_cost;
      }
      return null;*/
      return this.buildFDC;
    },
    areaAFMCostFilter() {
      /*if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .avg_fm_cost;
      }
      return null;*/
      return this.buildAFM;
    },
    areaFMCostFilter() {
      /*if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .fm_cost;
      }
      return null;*/
      return this.buildFM;
    },
    gfaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getGFAIndex]
          .gfa;
      }
      return null;
    },
    /** Finance - Specialities */
    specialitiesFinanceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaFinanceIndex
        ].finance;
      }
      return null;      
    },
    specialityFinanceNameFilter() {
      return this.getDataSlice (this.activeStageYear, "finance_total", "finance");
    },
    specialitiesFinanceTotalFilter()
    {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaFinanceIndex
        ].finance_total;
       }
      return null;
    },
    specialityFinanceTotalFilter() {
      return this.getDataSlice (this.activeStageYear, "finance_total", "finance_total");
    },
    specialitiesPatientEpisodesFilter()
    {
      if (this.$store.state.prototypedata.specialityData.length > 0) 
      {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaPatientIndex].patient_episodes;
      }
      return null;
    },
    specialitiesPatientEpisodesTotalFilter()
    {
      if (this.$store.state.prototypedata.specialityData.length > 0) 
      {
        return this.$store.state.prototypedata.specialityData[
          this.getSpecialityAreaPatientIndex].patient_episodes_total;
      }
      return null;  
    },
    specialityPatientEpisodesFilter() {
      return this.getDataSlice(this.activeStageYear, "patient_episodes_total", "patient_episodes");      
    },

    specialityPatientEpisodesTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityPatientEpisodesTotalFilter)
      );

      return str;
    },
    assetsFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "assets");
    },
    assetsLifeCycleFilter() 
    {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "life_cycle");
    },
    assetsQuantityFilter() 
    {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "assets_quantity");   
    },
   
    assetsAnnualisedCostFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "annualised_cost");
    },
    assetsAnnualisedCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedCostFilter)
      );

      return str;
    },
    assetsAnnualisedMaintenanceCostFilter() {
      return this.getDataSlice (this.activeStageYear, "annualised_cost", "annualised_maintenance_cost");
    },
    //-----------------------------------------------------------------------------------
    // SUMMARY ASSET
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Asset Table
    // Asset Filter
    summaryAssetFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets;
      }
      return null;
    },
    // Grand Summary
    // Summary Asset Table
    // Assets Quantity Filter
    summaryAssetQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        var i = this.getDataSetIndex("summary_assets");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_assets_quantity;
        }
      }
      return null;
    },
    summaryAssetQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetQuantityFilter)
      );

      return str;
    },
    // Grand Summary
    // Summary Asset Table
    // Asset Total Capital Cost Filter
    summaryAssetTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets_total_capital_cost;
      }
      return null;
    },
    summaryAssetTotalCapitalCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetTotalCapitalCostFilter)
      );

      return str;
    },

    // Grand Summary
    // Summary Patient Table
    // Patient Episodes Filter
    summaryPatientFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_episodes;
      }
      return null;
    },
    // Grand Summary
    // Summary Patient Table
    // Patient Quantity Filter
    summaryPatientQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_quantity;
      }
      return null;
    },
    summaryPatientQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryPatientQuantityFilter)
      );

      return str;
    },
    calculateAssetsTotalCapitalCost(assetsTotalCapitalCostArray) {
      let assetsTotalCapitalCostFloatArray = new Array();
      for (var i = 0; i < assetsTotalCapitalCostArray.length; i++) {
        assetsTotalCapitalCostFloatArray.push(
          parseFloat(assetsTotalCapitalCostArray[i])
        );
      }

      const total = assetsTotalCapitalCostFloatArray.reduce(function (a, b) {
        return a + b;
      });
      return total;
    },
  },
  methods: {   
    setMetricYearMethod()
    {
        this.metricUpdate = true;
        this.metricActivity = new Array ();
        this.metricMetrics = new Array ();
        this.metricBeneficiary = new Array ();
        this.metricHsiBenchmark = new Array ();
        this.metricInternationalBenchmark = new Array ();

        var currentYear = this.metricYear;

        if (typeof this.metricYear.year != "undefined")
        {
            currentYear = this.metricYear.year;
        }

        console.log ("setMetricYear: ", currentYear);       

        var index = -1;

        if (currentYear == this.yeardata[0])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearOne,
          "metrics"
        );        

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearOne[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearOne[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearOne[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearOne[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearOne[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearTwo,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearTwo[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearTwo[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearTwo[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearTwo[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearTwo[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearThree,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearThree[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearThree[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearThree[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearThree[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearThree[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFour,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearFour[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearFour[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearFour[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearFour[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearFour[index].international_benchmark;         
        }
      }

      if (currentYear == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFive,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearFive[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearFive[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearFive[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearFive[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearFive[index].international_benchmark;         
        }
      }
    },
    setBuildingsYearMethod()
    {
        this.buildUpdate = true;
        this.buildArea = new Array ();
        this.buildTFS = new Array ();
        this.buildFDC = new Array ();
        this.buildNBC = new Array ();
        this.buildAFM = new Array ();
        this.buildFM = new Array ();

        var currentYear = this.buildingsYear;

        if (typeof this.buildingsYear.year != "undefined")
        {
            currentYear = this.buildingsYear.year;
        }

        console.log ("setBuildingYear: ", currentYear);       

        var index = -1;

        if (currentYear == this.yeardata[0])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearOne,
          "total_floor_space"
        );        

        if (index > -1) 
        {
            this.buildArea = this.$store.state.prototypedata.stageThreeYearOne[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearOne[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearOne[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearOne[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearOne[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearOne[index].fm_cost;         
        }
      }

      if (currentYear == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearTwo,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearTwo[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearTwo[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearTwo[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearTwo[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearTwo[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearTwo[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearThree,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearThree[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearThree[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearThree[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearThree[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearThree[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearThree[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFour,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearFour[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearFour[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearFour[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearFour[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearFour[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearFour[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFive,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearFive[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearFive[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearFive[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearFive[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearFive[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearFive[index].fm_cost;      
           }
      }
    },

    getDataSlice (year, summary, dataIndex)
    {
      var index = -1;
   
      var newData = new Array ();

      if (year == this.yeardata[0]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearOne[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[1]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearTwo[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[2]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearThree[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[3]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFour[index].patient_episodes_total;
           }
        }
      }

      if (year == this.yeardata[4]) 
      {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          summary
        );

        //console.log ("index = ", index);

        if (index > -1) 
        {
           if (dataIndex == "finance") 
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].finance;
           }
           if (dataIndex == "finance_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].finance_total;
           }

           if (dataIndex == "workforce")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].workforce;
           }

           if (dataIndex == "workforce_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].workforce_quantity;
           }

           if (dataIndex == "cost_per_staff")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].cost_per_staff;
           }

           if (dataIndex == "total_workforce_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].total_workforce_cost;
           }

           if (dataIndex == "assets")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].assets;
           }

           if (dataIndex == "assets_quantity")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].assets_quantity;
           }

           if (dataIndex == "capital_cost_per_item")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].capital_cost_per_item;
           }

           if (dataIndex == "total_capital_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].total_capital_cost;
           }

           if (dataIndex == "life_cycle")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].life_cycle;
           }

           if (dataIndex == "annualised_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].annualised_cost;
           }

           if (dataIndex == "annualised_maintenance_cost")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].annualised_maintenance_cost;
           }

           if (dataIndex == "patient_episodes")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].patient_episodes;
           }

           if (dataIndex == "patient_episodes_total")
           {
            newData = this.$store.state.prototypedata.stageThreeYearFive[index].patient_episodes_total;
           }
        }
      }
      
       

      return newData;
    },
    getSpecialityAPI ()
    {
      var newString = "";

      newString = newString + this.selectedSpeciality.toString().toLowerCase();
      newString = newString + "_england_stage_3_";

     
      newString = newString + this.selectedYear.toString().toLowerCase();

      return newString;
    },
  
    displayData ()
    {
      console.log ("displayData");
      console.log ("");
      
      console.log ("specialityData length: ", this.$store.state.prototypedata.specialityData.length);
      console.log ("stageThreeYearOne length: ", this.$store.state.prototypedata.stageThreeYearOne.length);
      console.log (" ", this.$store.state.prototypedata.stageThreeYearOne);
      console.log ("stageThreeYearTwo length: ", this.$store.state.prototypedata.stageThreeYearTwo.length);
      console.log (" ", this.$store.state.prototypedata.stageThreeYearTwo);
      console.log ("stageThreeYearThree length: ", this.$store.state.prototypedata.stageThreeYearThree.length);
      console.log (" ", this.$store.state.prototypedata.stageThreeYearThree);
      console.log ("stageThreeYearFour length: ", this.$store.state.prototypedata.stageThreeYearFour.length);
      console.log (" ", this.$store.state.prototypedata.stageThreeYearFour);
      console.log ("stageThreeYearFive length: ", this.$store.state.prototypedata.stageThreeYearFive.length);
      console.log (" ", this.$store.state.prototypedata.stageThreeYearFive);

      console.log ("");

      console.log ("stageThree length :", this.$store.state.prototypedata.stageThree.length);
      console.log ("stageThree:", this.$store.state.prototypedata.stageThree);  

      console.log ("stageThreeYearOne: ", this.$store.state.prototypedata.stageThreeYearOne[0].patient_episodes);
      console.log ("stageThreeYearOne: ", this.$store.state.prototypedata.stageThreeYearOne[0].patient_episodes_total);
    },
    getTileData(tileNumber) {
      //console.log("--------------------------------------");
      //console.log("SummaryMain3: getTileData", tileNumber);

      let defaultValues = new Array();
      let index = -1;

      var debugValue = false;

      let currentvalue = "";

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[0],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[0],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[0],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[0],debugValue)));

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[1], debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[1], debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[1],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[1],debugValue)));

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[2],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[2],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[2],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[2],debugValue)));
      
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[3],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[3],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[3],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[3],debugValue)));

      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[4],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[4],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[4],debugValue)));
      defaultValues.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[4],debugValue)));

      index = this.getDataSetIndex("summary_overview");

      //console.log("GetTileData - getDataSetIndex", index);

      currentvalue = defaultValues[tileNumber];

      //console.log(
      //  "length: ",
      //  this.$store.state.prototypedata.stageThree[index].summary_overview
      //    .length
      //);

      if (index >= 0) {
        //console.log("index > 0");
        if (
          tileNumber <=
          this.$store.state.prototypedata.stageThree[index].summary_overview
            .length
        ) {
          //console.log("tileNumber");
          if (
            this.$store.state.prototypedata.stageThree[index].summary_overview[
              tileNumber
            ] == null ||
            this.$store.state.prototypedata.stageThree[index].summary_overview[
              tileNumber
            ].length == 0
          ) {
            currentvalue = defaultValues[tileNumber];
          } else {
            currentvalue =
              this.$store.state.prototypedata.stageThree[index]
                .summary_overview[tileNumber];
          }
        }
      }

      //console.log("currentvalue ", currentvalue);
      //console.log("--------------------------------------");
      return currentvalue;
    },
    setBuildingsYear()
    {
        this.buildUpdate = true;
        this.buildArea = new Array ();
        this.buildTFS = new Array ();
        this.buildFDC = new Array ();
        this.buildNBC = new Array ();
        this.buildAFM = new Array ();
        this.buildFM = new Array ();

        var currentYear = this.buildingsYear;

        if (typeof this.buildingsYear.year != "undefined")
        {
            currentYear = this.buildingsYear.year;
        }

        console.log ("setBuildingYear: ", currentYear);       

        var index = -1;

        if (currentYear == this.yeardata[0])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearOne,
          "total_floor_space"
        );        

        if (index > -1) 
        {
            this.buildArea = this.$store.state.prototypedata.stageThreeYearOne[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearOne[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearOne[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearOne[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearOne[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearOne[index].fm_cost;         
        }
      }

      if (currentYear == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearTwo,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearTwo[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearTwo[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearTwo[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearTwo[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearTwo[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearTwo[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearThree,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearThree[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearThree[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearThree[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearThree[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearThree[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearThree[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFour,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearFour[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearFour[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearFour[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearFour[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearFour[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearFour[index].fm_cost;      
        }
      }

      if (currentYear == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFive,
          "total_floor_space"
        );    

        if (index > -1) 
        {
          this.buildArea = this.$store.state.prototypedata.stageThreeYearFive[index].area;
            this.buildTFS = this.$store.state.prototypedata.stageThreeYearFive[index].total_floor_space;           
            this.buildFDC = this.$store.state.prototypedata.stageThreeYearFive[index].full_dev_cost;
            this.buildNBC = this.$store.state.prototypedata.stageThreeYearFive[index].net_build_cost;
            this.buildAFM = this.$store.state.prototypedata.stageThreeYearFive[index].avg_fm_cost;     
            this.buildFM = this.$store.state.prototypedata.stageThreeYearFive[index].fm_cost;      
           }
      }


    },
    setMetricYear()
    {
        this.metricUpdate = true;
        this.metricActivity = new Array ();
        this.metricMetrics = new Array ();
        this.metricBeneficiary = new Array ();
        this.metricHsiBenchmark = new Array ();
        this.metricInternationalBenchmark = new Array ();

        console.log ("setMetricYear: ", this.metricYear.year);       

        var index = -1;

        if (this.metricYear.year == this.yeardata[0])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearOne,
          "metrics"
        );        

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearOne[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearOne[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearOne[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearOne[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearOne[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[1])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearTwo,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearTwo[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearTwo[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearTwo[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearTwo[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearTwo[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[2])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearThree,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearThree[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearThree[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearThree[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearThree[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearThree[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[3])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFour,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearFour[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearFour[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearFour[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearFour[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearFour[index].international_benchmark;         
        }
      }

      if (this.metricYear.year == this.yeardata[4])
        {
           index = this.getIndex(
              this.$store.state.prototypedata.stageThreeYearFive,
          "metrics"
        );    

        if (index > -1) 
        {
            this.metricActivity = this.$store.state.prototypedata.stageThreeYearFive[index].activity;
            this.metricMetrics = this.$store.state.prototypedata.stageThreeYearFive[index].metrics;
            this.metricBeneficiary = this.$store.state.prototypedata.stageThreeYearFive[index].beneficiary;
            this.metricHsiBenchmark = this.$store.state.prototypedata.stageThreeYearFive[index].hsi_benchmark;
            this.metricInternationalBenchmark = this.$store.state.prototypedata.stageThreeYearFive[index].international_benchmark;         
        }
      }


    },
    setYear()
    {
        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        //console.log ("setYear: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

       this.apiPayload.apiString = "";
       this.apiPayload.reference = "4";

       this.fetchSpecialityData();

    },
    getSpecialityPulldownOptions()
    {
       console.log ("setYear: ", this.selectedYear.year);       
       console.log ("setYear: this.apiPayload.hospital: ", this.apiPayload.hospital);

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        console.log ("setYear: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        console.log ("setYear: this.apiPayload.speciality: ", this.apiPayload.speciality);

       this.apiPayload.apiString = "";
       this.apiPayload.reference = "4";

       this.fetchSpecialityData();

    },
    setSpeciality ()
    {                
        console.log ("setSpeciality: this.apiPayload.hospital: ", this.apiPayload.hospital);

        if (typeof this.selectedYear.year != "undefined") 
        {
          this.apiPayload.year = this.selectedYear.year;
        }
        else if (typeof this.selectedYear != "undefined") 
        {
          this.apiPayload.year = this.selectedYear;
        }

        console.log ("setSpeciality: this.apiPayload.year: ", this.apiPayload.year);

        if (typeof this.selectedSpeciality.speciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality.speciality;
        }
        else if (typeof this.selectedSpeciality != "undefined") 
        {
          this.apiPayload.speciality = this.selectedSpeciality;
        }

        console.log ("setSpeciality: this.apiPayload.speciality: ", this.apiPayload.speciality);

        this.apiPayload.apiString = "";
        this.apiPayload.reference = "5";

        this.fetchSpecialityData ();

    },
    setHospital ()
    {
        //this.specialityHospital (this.selectedNewHospital.speciality);

        this.apiPayload.year = this.selectedYear.year;
        this.apiPayload.speciality = this.selectedSpeciality.speciality;
        
        this.apiPayload.apiString = "";
        this.apiPayload.reference = "6";

        this.fetchSpecialityData ();

    },  
    fetchSpecialityData() {
      //console.log ("api ==================================");
      //console.log("api.speciality = ", this.apiPayload.speciality);
      //console.log("api.year = ", this.apiPayload.year);
      //console.log("api.hospital = ", this.apiPayload.hospital);
      //console.log("api.apiString = ", this.apiPayload.apiString);
      
      //console.log("api.apiString =", this.apiPayload.apiString);

      this.apiPayload.yearApi = "";

if (this.apiPayload.year == this.yeardata[0])
{
  this.apiPayload.yearApi = "Year1";
}

if (this.apiPayload.year == this.yeardata[1])
{
  this.apiPayload.yearApi = "Year2";
}

if (this.apiPayload.year == this.yeardata[2])
{
  this.apiPayload.yearApi = "Year3";
}

if (this.apiPayload.year == this.yeardata[3])
{
  this.apiPayload.yearApi = "Year4";
}

if (this.apiPayload.year == this.yeardata[4])
{
  this.apiPayload.yearApi = "Year5";
}

      this.$store
        .dispatch("GetSpecialityDataByYearNew", this.apiPayload)
        .then((data) => {
          console.log("data", data);

          this.logData ();
        });
    },
    retrieveTileInformation ()
    {
        var debugVal = true;
        this.tileInformation = new Array ();

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[0], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[0], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[0], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[0], debugVal)));
      
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[1], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[1], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[1], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[1], debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[2], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[2], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[2], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[2], debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[3], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[3], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[3], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[3], debugVal)));

        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("patientEpisodes", this.yeardata[4], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("assets", this.yeardata[4], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("workforce", this.yeardata[4], debugVal)));
        this.tileInformation.push(this.numberWithCommas(this.calculateSummaryValue("finance", this.yeardata[4], debugVal)));

        //console.log ("this.tileinformation: ", this.tileInformation);

    },  
    logData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        console.log(
          "logData: length: ",
          this.$store.state.prototypedata.specialityData.length
        );
        for (
          var i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          console.log(
            "item[",
            i,
            "]=",
            this.$store.state.prototypedata.specialityData[i]
          );
        }
      }
    },
    fetchData(speciality) {
      //console.log ("fetchData: ", speciality.apiString);

      if (typeof speciality.apiString != "undefined") 
      {
        this.$store
          .dispatch("attemptFetchStageThree", speciality)
          .then((data) => {
            //console.log("stage 3 - fetched data:");
            if (typeof data == "undefined") 
            {
              console.log(data);
            }

            this.fillData(this.activeSummary, this.activeStageYear);
          });
        }
        //else
        //{
          //console.log ("fetchData: ", speciality);
          //console.log ("was abandoned.");
        //}
    }, 
    fetchDataSummaries() {
      console.log ("==> fetchDataSummaries");
      var option = "get_grand_summary_new_bdf_stage_3_"
      console.log ("option = ", option);
      
      this.$store.dispatch("getBDFStageThreeSummaries", option).then((data) => {
        if (typeof data != "undefined") 
        {
          console.log ("data: ", data);
        }

        this.fillData(this.activeSummary, this.activeStageYear);
      });
      //console.log ("<== fetchDataSummaries");
    },
    getMongoDBData() {
      //console.log ("SummaryMain3.vue: getMongoDBData: this.apiPayload: ", this.apiPayload);
      this.$store.dispatch("GetData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityArea(specialityTypeName) {
      if (specialityTypeName == "Patient Episodes") {
        this.specialityType = "patientEpisodes";
      }
      if (specialityTypeName == "Finance") {
        this.specialityType = "finance";
      }
      if (specialityTypeName == "Workforce") {
        this.specialityType = "workforce";
      }
      if (specialityTypeName == "Space & Property") {
        this.specialityType = "space";
      }      
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    getIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

    

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            if (item in dataStruct[i]) {
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },
    searchIndex(dataStruct, item) {
      var id = -1;
      var i = 0;

      if (typeof dataStruct != "undefined") {
        //console.log("datastruct", dataStruct);
        if (dataStruct.length > 0) {
          for (i = 0; i < dataStruct.length; i++) {
            //console.log("dataStruc[i]", dataStruct[i]);
            if (item == dataStruct[i]) {
              //console.log("item == dataStruct!");
              id = i;
            }
          }
        }
      } else {
        //console.log("GetIndex: dataStruct is undefined");
      }

      return id;
    },
   
    fillData(tileValue, year) {
      //console.log ("fillData: (activeSummary): tileValue", tileValue);
      this.activeSummary = tileValue;
      this.activeStageYear = year;

      //console.log ("activeSummary: ", this.activeSummary);

      this.chartOptions = {
        responsive : true
      }

      this.datacollection = {
        labels: this.getBarGraphLabels(),
        datasets: [
          {
            label: this.yeardata[0],
            data: this.getBarGraphDataValues(this.yeardata[0]),
            backgroundColor: ["rgba(255, 99, 132, 0.2)"], // line 1
            borderColor: ["rgb(255, 99, 132)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[1],
            data: this.getBarGraphDataValues(this.yeardata[1]),
            backgroundColor: ["rgba(75, 192, 192, 0.2)"], // line 4
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[2],
            data: this.getBarGraphDataValues(this.yeardata[2]),
            backgroundColor: ["rgba(54, 162, 235, 0.2)"], // line 5
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[3],
            data: this.getBarGraphDataValues(this.yeardata[3]),
            backgroundColor: ["rgba(255, 159, 64, 0.22)"], // line 2
            borderColor: ["rgb(255, 159, 64)"],
            borderWidth: 1,
          },
          {
            label: this.yeardata[4],
            data: this.getBarGraphDataValues(this.yeardata[4]),
            backgroundColor: ["rgba(255, 205, 86, 0.2)"],
            borderColor: ["rgb(255, 205, 86)"], // line 3
            borderWidth: 1,
          },
        ],
      };

      //console.log ("this.datacollection: ", this.datacollection);
    },
    getBarGraphDataValues(year) {
      var data = new Array();    
      var debugVal = false;
      if (this.activeSummary == 'workforceCostYear1' || 
          this.activeSummary == 'workforceCostYear2' || 
          this.activeSummary == 'workforceCostYear3' || 
          this.activeSummary == 'workforceCostYear4' || 
          this.activeSummary == 'workforceCostYear5')
      {
        data.push(this.calculateSummaryValue("workforce", year,debugVal));
      } else if (this.activeSummary == 'assetsYear1' || 
                 this.activeSummary == 'assetsYear2' || 
                 this.activeSummary == 'assetsYear3' || 
                 this.activeSummary == 'assetsYear4' || 
                 this.activeSummary == 'assetsYear5') 
      {
        data.push(this.calculateSummaryValue("assets", year,debugVal));
      } else if (this.activeSummary == 'financeYear1' || 
                 this.activeSummary == 'financeYear2' || 
                 this.activeSummary == 'financeYear3' || 
                 this.activeSummary == 'financeYear4' || 
                 this.activeSummary == 'financeYear5') 
      {
        data.push(this.calculateSummaryValue("finance", year,debugVal));
      } else if (this.activeSummary == 'patientEpisodeYear1' || 
                 this.activeSummary == 'patientEpisodeYear2' || 
                 this.activeSummary == 'patientEpisodeYear3' || 
                 this.activeSummary == 'patientEpisodeYear4' || 
                 this.activeSummary == 'patientEpisodeYear5') 
      {
        data.push(this.calculateSummaryValue("patientEpisodes", year,debugVal));
      }

      if (data.length == 0)
      {
        data.push ("0");
      }

      //console.log ("data 28: ", data);

      return data;
    },
   getBarGraphLabels() {
      var data = new Array();

      if (this.activeSummary == 'workforceCostYear1' || 
          this.activeSummary == 'workforceCostYear2' || 
          this.activeSummary == 'workforceCostYear3' || 
          this.activeSummary == 'workforceCostYear4' || 
          this.activeSummary == 'workforceCostYear5') {
        data.push("Workforce");
      } else if (this.activeSummary == 'assetsYear1' || 
                 this.activeSummary == 'assetsYear2' || 
                 this.activeSummary == 'assetsYear3' || 
                 this.activeSummary == 'assetsYear4' || 
                 this.activeSummary == 'assetsYear5') {
        data.push("Assets");
      } else if (this.activeSummary == 'financeYear1' || 
                 this.activeSummary == 'financeYear2' || 
                 this.activeSummary == 'financeYear3' || 
                 this.activeSummary == 'financeYear4' || 
                 this.activeSummary == 'financeYear5') {
        data.push("Finance");
      } else if (this.activeSummary == 'patientEpisodeYear1' || 
                 this.activeSummary == 'patientEpisodeYear2' || 
                 this.activeSummary == 'patientEpisodeYear3' || 
                 this.activeSummary == 'patientEpisodeYear4' || 
                 this.activeSummary == 'patientEpisodeYear5') {
        data.push("Patient Episodes");
      }

      return data;
    },
    getGraphTitle() {
      var str;

      if (this.activeSummary == 'workforceCostYear1' || 
          this.activeSummary == 'workforceCostYear2' || 
          this.activeSummary == 'workforceCostYear3' || 
          this.activeSummary == 'workforceCostYear4' || 
          this.activeSummary == 'workforceCostYear5') {
        str = "Workforce Quantity";
      } else if (this.activeSummary == 'assetsYear1' || 
          this.activeSummary == 'assetsYear2' || 
          this.activeSummary == 'assetsYear3' || 
          this.activeSummary == 'assetsYear4' || 
          this.activeSummary == 'assetsYear5') {
        str = "Asset Quantity";
      } else if (this.activeSummary == 'financeYear1' || 
                 this.activeSummary == 'financeYear2' || 
                 this.activeSummary == 'financeYear3' || 
                 this.activeSummary == 'financeYear4' || 
                 this.activeSummary == 'financeYear5') {
        str = "Finance Quantity";
      } else if (this.activeSummary == 'patientEpisodeYear1' || 
                 this.activeSummary == 'patientEpisodeYear2' || 
                 this.activeSummary == 'patientEpisodeYear3' || 
                 this.activeSummary == 'patientEpisodeYear4' || 
                 this.activeSummary == 'patientEpisodeYear5') {
        str = "Patient Episodes Quantity";
      }
      return str;
    },
    calculateSummaryValue(summary, year, debug) {
      if (debug)
      {
          console.log("calculateSummaryValue :", summary + " " + year);
      }
      
      let summaryValues = new Array();
      let index = -1;
      let totalValue = "";

      if (summary == "population" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "population"
        );

        //console.log ("index = ", index );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_workforce_quantity;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforced yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
              console.log("workforce yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[0]) {
        //console.log ("this.$store.state.prototypedata.stageThreeYearOne: ", this.$store.state.prototypedata.stageThreeYearOne);
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_assets"
        );

        //console.log ("index = ", index);

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_assets_quantity;

          //console.log ("summaryValues: ", summaryValues);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_patient_quantity"
        );

        //console.log ("index = ", index);

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_patient_quantity;

           //console.log ("summaryValues : ", summaryValues);


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_patient_quantity;

        if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_patient_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_patient_quantity;

       
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr4 = ", totalValue);
            }
       
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_patient_quantity;

         
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr5 = ", totalValue);
            }
          
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearOne[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearTwo[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearThree[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "finance_total"
        );

        if (index > -1) {
          var temp_val = 0;
          var finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearFour[index].finance,
            "Income Requirement"
          );


          temp_val =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

         if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearFive[index].finance,
            "Income Requirement"
          );

         temp_val =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);
   
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr5 = ", totalValue);
            }
            }
        }
      }

      //console.log ("return totalValue ", totalValue);

      return totalValue;
    },
    getDataValues(summary, year, debug) {
      if (debug)
      {
          console.log("calculateSummaryValue :", summary + " " + year);
      }
      
      let summaryValues = new Array();
      let index = -1;
      let totalValue = "";

      if (summary == "population" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "population"
        );

        //console.log ("index = ", index );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "population" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "population"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .population;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_workforce_quantity;


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr1 = ", totalValue);
            }
            }
        }
      }

      if (summary == "workforce" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforced yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
              console.log("workforce yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "workforce" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_workforce"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_workforce_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("workforce yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "assets" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_assets"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_assets_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("assets yr5 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "summary_patient_quantity"
        );

        //console.log ("index = ", index);

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .summary_patient_quantity;

           //console.log ("summaryValues : ", summaryValues);


          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .summary_patient_quantity;

        if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .summary_patient_quantity;

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .summary_patient_quantity;

       
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr4 = ", totalValue);
            }
       
          }
        }
      }

      if (summary == "patientEpisodes" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "summary_patient_quantity"
        );

        if (index > -1) {
          summaryValues =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .summary_patient_quantity;

         
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("patientEpisodes yr5 = ", totalValue);
            }
          
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[0]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearOne,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearOne[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearOne[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance yr1 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[1]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearTwo,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearTwo[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearTwo[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr2 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[2]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearThree,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearThree[index].finance,
            "Income Requirement"
          );

          temp_val =
            this.$store.state.prototypedata.stageThreeYearThree[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr3 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[3]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFour,
          "finance_total"
        );

        if (index > -1) {
          var temp_val = 0;
          var finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearFour[index].finance,
            "Income Requirement"
          );


          temp_val =
            this.$store.state.prototypedata.stageThreeYearFour[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);

         if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr4 = ", totalValue);
            }
          }
        }
      }

      if (summary == "finance" && year == this.yeardata[4]) {
        index = this.getIndex(
          this.$store.state.prototypedata.stageThreeYearFive,
          "finance_total"
        );

        if (index > -1) {
          temp_val = 0;
          finance_index = this.searchIndex(
            this.$store.state.prototypedata.stageThreeYearFive[index].finance,
            "Income Requirement"
          );

         temp_val =
            this.$store.state.prototypedata.stageThreeYearFive[index]
              .finance_total[finance_index];

          summaryValues.push(temp_val);
   
          if (summaryValues) {
            totalValue = this.calculateArray(summaryValues);
            if (debug)
            {
            console.log("finance_total yr5 = ", totalValue);
            }
            }
        }
      }

      return totalValue;
    },
    
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    calculateArray(data) {
      let arrayValuesFloatArray = new Array();
      let str = "";
      let parse = false;

      if (typeof data != "undefined") {
        str = "";
        parse = false;

        for (var i = 0; i < data.length; i++) 
        {
          str = String(data[i]);
          str = str.replaceAll(",", "");
          str = str.replaceAll("-", "");

          if (str.includes(".")) {
            parse = true;
          }

          if (str.length > 0)
          {
            arrayValuesFloatArray.push(parseFloat(str));
          }
        }

        //console.log ("arrayValuesFloatArray: ", arrayValuesFloatArray );
      }

      if (arrayValuesFloatArray.length > 0)
      {

          const total = arrayValuesFloatArray.reduce(function (a, b) 
          {
              return a + b;
          });
      if (parse == true) {
        return total.toFixed(2);
      } else {
        return total;
      }
    }
    else
    {
      return 0;
    }
    },
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.stageThree.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.stageThree.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.stageThree[i]) {
            id = i;
          }
        }
      }

      //console.log("getDataSetIndex", item, id);

      return id;
    },
    getPopulationTotal (year)    
    {
      var item = "";
      item = this.numberWithCommas(this.calculateSummaryValue("population", year, false));

      //console.log ("population item:", item);

      return item;
    },
    // Summary Overview
    // Controls the information on the boxes within the Stage
    specialityOverviewFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_overview")
        ].summary_overview;
      }
      return null;
    },
    // 
    setSpecialityName(specialityName) {
      this.activeSpeciality = specialityName;
    },
    //
    editImprovementType(specialityTypeName) {
      //console.log ("editImprovement: ", specialityTypeName);
      if (specialityTypeName == "Diagnostics") {
        this.improvementType = "diagnostics";
      }
      if (specialityTypeName == "Outpatient Clinics") {
        this.improvementType = "OPD";
      }
      if (specialityTypeName == "OR") {
        this.improvementType = "OR";
      }
      if (specialityTypeName == "Emergency Room (ER)") {
        this.improvementType = "ER";
      }

      if (specialityTypeName == "Bed Flow") {
        this.improvementType = "bedflow";
      }

      //console.log(
//        "editImprovementType (",
  //      specialityTypeName,
    //    ")=",
      //  this.improvementType
      //);
    },
    //getApiPrefix ()
    //{      
      //var fullAPIString = "get_grand_summary" + this.selectedHospitalPrefix + "stage_3_";
      //return fullAPIString;
    //},
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        //console.log ("SummaryMain3.vue: Stage");

        //console.log ("selectedHospitalPrefix: ", this.selectedHospitalPrefix);

        
        //console.log ("4. ", fullAPIString);

        //console.log ("fullAPIString: = ", fullAPIString);

        this.fetchDataSummaries();
        this.retrieveTileInformation ();
        //console.log ("tileInformation: ", this.tileInformation);
        this.apiPayload.apiString = "grandsummarytwo-collection";
        this.apiPayload.reference = "7";

        //console.log("stage: getData");

        this.getMongoDBData();
        this.fillData("workforceCostYear1", this.activeStageYear);
      }

      //if (this.stageSummary == "buildingsFM") {
//        this.apiPayload.apiString = "fm_build3_collection";
  //      this.apiPayload.reference = "8";
    //    this.getMongoDBData();
      //}

      if (this.stageSummary == "specialities") {
        //console.log(
        //  " this.selectedSpecialityArea.selected",
        //  this.selectedSpecialityArea
       // );

        //this.editSpeciality
        this.editSpecialityArea(this.selectedSpecialityArea);
        //this.specialityApi = this.getSpecialityAPI();        
        //this.apiPayload.apiString = this.specialityApi;


        this.getSpecialityPulldownOptions ();



        this.apiPayload.reference = "9";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "metrics")
      {
        this.setMetricYearMethod ();
      }

      if (this.stageSummary == "buildingsFM")
      {
        this.setBuildingsYearMethod();
      }

      if (this.stageSummary == "opImprov") {
       // console.log(
       //   " this.improvementSpecialityAra.selected",
       //   this.improvementSpecialityArea
      //  );

        this.editImprovementType(this.improvementSpecialityArea);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      }
    },
  },
};
</script>
<style>
.chartcontainer3 {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 450px;
  display: inline-block;
  width: 600px; /* was 26 rem */
  margin-right: 20px;
}
.title_text3 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.chart_view3 {
  width: 600px;
  height: 400px;
  /*border: solid #5b6dcd 10px;*/
  box-sizing: content-box;
}
</style>
